import { lazy, Suspense } from 'react'
import { ToastProvider } from '@radix-ui/react-toast'
import type { ReactNode } from 'react'

import AppSkeleton from '@/modules/Layout/Skeleton'

import { useAlertStore } from '@/utils/store/alert'
import { useConfirmationAlertStore } from '@/utils/store/confirmationAlert'
import { usePinnedNotes } from '@/utils/store/pinnedNotes'
import { useToastStore } from '@/utils/store/toast'

const Alert = lazy(() => import('@/components/Alert'))
const Toast = lazy(() => import('@/components/Toast'))
const PinnedNotes = lazy(() => import('@/components/PinnedNotes'))
const ConfirmationAlert = lazy(() => import('@/components/ConfirmationAlert'))

type Props = {
  children: ReactNode
}

/**
 * The DefaultProvider is a wrapper for the entire application.
 * It provides: Banners and Alerts
 */
export function DefaultProvider({ children }: Props) {
  const [currentBanner, dismissBanner] = useToastStore(
    ({ current, dismiss }) => [current, dismiss],
  )

  const { isShowing, notes, dismissNote } = usePinnedNotes((state) => ({
    dismissNote: state.hidePinnedNotes,
    isShowing: state.isShowing,
    notes: state.notes,
    setNotes: state.setNotes,
  }))

  const [currentAlert, dismissAlert] = useAlertStore(({ current, dismiss }) => [
    current,
    dismiss,
  ])
  const [currentConfirmationAlert, dismissConfirmationAlert] =
    useConfirmationAlertStore(({ current, dismiss }) => [current, dismiss])

  return (
    <ToastProvider>
      <Suspense fallback={<AppSkeleton />}>{children}</Suspense>
      <Suspense fallback={null}>
        {isShowing ? (
          <PinnedNotes dismissNote={dismissNote} notes={notes} />
        ) : null}
      </Suspense>

      <Suspense fallback={null}>
        {Boolean(currentAlert) && (
          <Alert {...currentAlert} onClose={() => dismissAlert()}>
            {currentAlert.message ?? currentAlert.description}
          </Alert>
        )}
        {Boolean(currentConfirmationAlert) && (
          <ConfirmationAlert
            description={currentConfirmationAlert.description}
            keyToConfirm={currentConfirmationAlert.keyToConfirm}
            onClose={() => dismissConfirmationAlert()}
            onConfirm={currentConfirmationAlert.onConfirm}
            title={currentConfirmationAlert.title}
          >
            {currentConfirmationAlert.children}
          </ConfirmationAlert>
        )}
        {/*
          Toast component manages to render null if
          there are no values present, so we don't need
          to check for currentBanner.
        */}
        <Toast
          description={currentBanner?.description}
          duration={currentBanner?.duration}
          onClose={() => dismissBanner()}
          open={Boolean(currentBanner)}
          title={currentBanner?.title}
          type={currentBanner?.type}
        />
      </Suspense>
    </ToastProvider>
  )
}
