import { Helmet } from 'react-helmet-async'

import { isPartner } from '@/utils/constants'
import { StorageKey } from '@/utils/constants/enums'
import * as storage from '@/utils/storage'

export function PageMeta({ title }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta content={title} property="og:title" />
    </Helmet>
  )
}

export default function Headers() {
  const locale =
    storage.get(StorageKey.Language) ?? navigator.language.replace(/-.*/, '')
  const defaultTitle = `OCHO ${isPartner ? 'Partner' : 'Servicing'}`

  return (
    <Helmet defaultTitle={defaultTitle} titleTemplate={`%s - ${defaultTitle}`}>
      <html lang={locale} />

      {isPartner ? (
        <>
          <meta content="#C2417D" name="theme-color" />,
          <link href="/icons/favicon-partner.png" rel="icon" />,
          <link href="/touch-icon-partner.png" rel="apple-touch-icon" />
        </>
      ) : (
        <>
          <meta content="#11CD67" name="theme-color" />,
          <link href="/icons/favicon-servicing.png" rel="icon" />,
          <link href="/touch-icon-servicing.png" rel="apple-touch-icon" />
        </>
      )}
    </Helmet>
  )
}
