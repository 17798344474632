import styled, { css } from 'styled-components'

import { Directions } from '@/utils/constants/enums'

import type { Decorator } from './index'

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: var(--space--small);

  > span {
    opacity: 0.6;
    font-size: var(--font_size--small);
    margin-bottom: var(--space--xsmall);
  }

  > p {
    font-size: var(--font_size--medium);
    margin-bottom: var(--space--xsmall);
  }
`

export const InputWrapper = styled.div<{
  $prefix?: string
  $readOnly?: boolean
}>`
  border-radius: var(--corner_radius--medium);
  position: relative;
  border: 1px solid var(--color--background__neutral);

  input,
  textarea {
    border: none;
  }

  ${({ $prefix }) =>
    $prefix &&
    css`
      &:before {
        position: absolute;
        top: 0;
        left: 2px;
        font-size: var(--font_size--medium);
        font-weight: var(--font_weight--medium);
        color: var(--color--text__neutral);
        padding: var(--space--small) var(--space--xsmall);
        content: '${$prefix}';
      }
    `}
  ${({ $readOnly }) =>
    $readOnly
      ? css`
          padding: 0;
          border: none;
          border-radius: 0;
        `
      : undefined}
`

export const Input = styled.input<{ $prefix?: string; $decorator?: Decorator }>`
  color: var(--color--text__neutral);
  border-radius: var(--corner_radius--medium);
  border: 1px solid var(--color--background__neutral);
  background-color: var(--color--background__solid);
  display: block;
  padding: ${({ readOnly }) =>
    readOnly
      ? 'var(--space--small) 0'
      : 'var(--space--small) var(--space--medium)'};
  width: 100% !important;
  outline: none;

  &:focus {
    box-shadow: ${({ readOnly }) =>
      readOnly ? undefined : '0 0 0 2.5px var(--color--solid__primary)'};
  }

  ${({ $prefix }) =>
    $prefix &&
    css`
      padding-left: calc(${$prefix.length}ch * 2);
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.55;
    `}
  ${({ readOnly }) =>
    readOnly &&
    css`
      cursor: text;
      border-radius: 0;
    `}
  ${({ $decorator }) => {
    if ($decorator?.direction === Directions.LTR) {
      return 'padding-left: var(--space--xlarge);'
    }

    if ($decorator?.direction === Directions.RTL) {
      return 'padding-right: var(--space--xlarge);'
    }
  }}
`
export const InputContainer = styled.fieldset`
  max-width: 100%;
  position: relative;
  border: none;
  padding: 0;
  gap: var(--space--xxsmall);
  display: flex;
  flex-direction: column;
  margin: 0;
`

export const DecoratorContainer = styled.div<{ $direction: Directions }>`
  width: inherit;
  height: inherit;
  color: var(--color--solid__primary);
  position: relative;

  svg {
    position: absolute;
    width: 20px;
    height: 20px;
  }

  ${({ $direction }) => {
    if ($direction) {
      return css`
        svg {
          top: 7px;
          ${$direction === Directions.LTR
            ? 'left: var(--space--small);'
            : 'right: var(--space--small);'}
        }
      `
    }
  }}
`

export const Postfix = styled.span<{ $valueLength: number }>`
  position: absolute;
  top: var(--space--small);
  left: ${({ $valueLength }) => $valueLength + 2}ch;
`
