import { Button } from '@ocho/aurora'
import { useTranslation } from 'react-i18next'

import Dialog from '@/components/Dialog'
import { Flex } from '@/components/system'

import { ConsentList } from './styled'

type Props = {
  handleSetLocation: () => void
  handleSignOut: () => void
}
function ConsentsDialog({ handleSetLocation, handleSignOut }: Props) {
  const { t } = useTranslation()

  return (
    <Dialog
      footerActions={
        <Button $variant="primary" onClick={handleSetLocation}>
          {t('continue')}
        </Button>
      }
      onClose={handleSignOut}
      title={t('containers.selectlocation.consentDialog.title')}
      narrow
    >
      <Flex
        as={ConsentList}
        flexDirection="column"
        gap="var(--space--medium)"
        paddingX="var(--space--medium)"
      >
        <li>{t('selectLocation.disclaimerList.0')}</li>
        <li>{t('selectLocation.disclaimerList.1')}</li>
        <li>{t('selectLocation.disclaimerList.2')}</li>
        <li>{t('selectLocation.disclaimerList.3')}</li>
      </Flex>
    </Dialog>
  )
}

export default ConsentsDialog
