import { create } from 'zustand'

import type { Note } from '@/utils/api/note/types'

type PinnedNotesState = {
  isShowing: boolean
  notes: Note[]
  showPinnedNotes: () => void
  hidePinnedNotes: () => void
  setNotes: (notes: Note[]) => void
}

export const usePinnedNotes = create<PinnedNotesState>((set) => ({
  hidePinnedNotes: () => set({ isShowing: false }),
  isShowing: false,
  notes: [],
  setNotes: (notes) => set({ notes }),
  showPinnedNotes: () => set({ isShowing: true }),
}))
