import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'navbar main';
`

export const Content = styled.main<{ $paddingless?: boolean }>`
  grid-area: main;
  min-height: 0;
`
