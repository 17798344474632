import { captureException } from '@sentry/react'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import type { DefaultOptions } from '@tanstack/react-query'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import {
  persistQueryClient,
  removeOldestQuery,
} from '@tanstack/react-query-persist-client'
import { type AxiosError, isAxiosError } from 'axios'

import * as storage from '@/utils/storage'

import {
  getResponseContext,
  MutationError,
  normalizeKey,
  QueryError,
} from './utils'

const queryCache = new QueryCache({
  onError(error: Error | AxiosError | unknown, query) {
    // ignore axios errors since they're handled in 'src/utils/api/interceptors.ts'
    if (isAxiosError(error)) return

    captureException(new QueryError(error as Error), (scope) => {
      scope.setLevel('warning')

      const key = normalizeKey(query.queryKey)

      scope.setContext('Query', { key })

      if (typeof error === 'object' && error && 'response' in error) {
        scope.setContext('Response', getResponseContext(error?.response))
      }

      if (typeof key === 'object' && Array.isArray(key)) {
        scope.setFingerprint([
          ...key,
          (error as AxiosError)?.response?.status?.toString() ?? '',
        ])
      }

      return scope
    })
  },
})

const mutationCache = new MutationCache({
  // eslint-disable-next-line max-params
  onError(error, mutationVariables, _context, mutation) {
    // ignore axios errors since they're handled in 'src/utils/api/interceptors.ts'
    if (isAxiosError(error)) return

    captureException(new MutationError(error as Error), (scope) => {
      scope.setLevel('warning')

      const key = normalizeKey(mutation.options.mutationKey)

      let variables
      try {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        variables = JSON.stringify(mutation.state.variables, null, 2)
      } catch {
        // Do nothing
      }

      scope.setContext('Mutation', { key, variables })

      try {
        scope.setContext(
          'Response',
          getResponseContext((error as AxiosError)?.response),
        )
      } catch {
        void 0
      }

      if (typeof key === 'object' && Array.isArray(key)) {
        scope.setFingerprint([
          ...key,
          (error as AxiosError)?.response?.status?.toString() ?? '',
        ])
      }

      return scope
    })
  },
})

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const ONE_HOUR = 1000 * 60 * 60 * 3
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const ONE_MINUTE = 1000 * 60

const defaultOptions: DefaultOptions<AxiosError> = {
  mutations: {
    networkMode: 'offlineFirst',
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    retryDelay: (attemptCount) => Math.min(1000 * 2 ** attemptCount, 15000),
  },
  queries: {
    gcTime: ONE_HOUR,
    networkMode: 'offlineFirst',
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    retry: false,
    staleTime: ONE_MINUTE,
  },
}

const queryClient = new QueryClient({
  defaultOptions,
  mutationCache,
  queryCache,
})

if (storage.isValid?.()) {
  const persister = createSyncStoragePersister({
    retry: removeOldestQuery,
    storage: window.localStorage,
  })

  persistQueryClient({ persister, queryClient })
}

export default queryClient
