import oLine from './o-line.gif'
import { Animation, Container, Content, Message } from './styled'

function Spinner({
  full,
  size = 'default',
  message,
}: {
  full?: boolean
  size?: 'default' | 'medium' | 'small' | 'xsmall'
  message?: string
}): JSX.Element {
  return (
    <Container $full={full} role="progressbar">
      <Content>
        <Animation $size={size} alt="" src={oLine} />
      </Content>
      {message ? <Message>{message}</Message> : null}
    </Container>
  )
}

export default Spinner
