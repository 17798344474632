import { useState } from 'react'
import { type AuthSession, signOut } from 'aws-amplify/auth'
import { useTranslation } from 'react-i18next'

import AppError from '@/modules/AppError'

import Dialog from '@/components/Dialog'
import Spinner from '@/components/Spinner'

import { useLocations } from '@/utils/api/partner/hooks'
import { StorageKey } from '@/utils/constants/enums'
import * as storage from '@/utils/storage'
import { useAuthStore, usePartnerData } from '@/utils/store/auth'
import { usePreferencesStore } from '@/utils/store/preferences'

import ConsentsDialog from './ConsentsDialog'
import NoPartnerDialog from './NoPartnerDialog'
import SelectStoreDialog from './SelectStoreDialog'

function getPartnerDataFromAuthSession(authSession: AuthSession) {
  const partner =
    authSession?.tokens?.accessToken?.payload?.['cognito:groups']?.[0]
  const name = partner?.split(':')?.[2]

  return { name }
}

function SelectLocation() {
  const { t } = useTranslation()

  const { setUser } = useAuthStore(({ setUser }) => ({ setUser }))
  const setPreferredLocation = usePreferencesStore(
    ({ setLocation }) => setLocation,
  )
  const { name } = usePartnerData()
  const [indexSelected, setIndexSelected] = useState('')
  const [selectedLocation, setSelectedLocation] = useState<null | {
    id: string
    externalId: string
  }>(null)

  const { data, isLoading, error } = useLocations({ name })

  async function handleSignOut() {
    await signOut()

    storage.clear()
    setUser(null)

    location.reload()
  }

  function changeHandler(event) {
    setIndexSelected(event.target.value)
  }

  function handleSetLocation() {
    storage.set(StorageKey.SelectedLocation, JSON.stringify(selectedLocation))

    setPreferredLocation(selectedLocation)
  }

  if (!name) return <NoPartnerDialog handleSignOut={handleSignOut} />

  if (!data) {
    return (
      <Dialog title={t('selectLocation.header')} narrow>
        {isLoading ? (
          <Spinner message={t('selectLocation.loading.message')} />
        ) : (
          <AppError error={error} />
        )}
      </Dialog>
    )
  }

  if (selectedLocation)
    return (
      <ConsentsDialog
        handleSetLocation={handleSetLocation}
        handleSignOut={handleSignOut}
      />
    )

  return (
    <SelectStoreDialog
      changeHandler={changeHandler}
      data={data}
      handleSetLocation={() => setSelectedLocation(data[indexSelected])}
      handleSignOut={handleSignOut}
      indexSelected={indexSelected}
    />
  )
}

export { getPartnerDataFromAuthSession }
export default SelectLocation
