/* eslint-disable max-lines */
import { TagType } from '@ocho/aurora'

export const CustomerTaskNames = [
  'DOC_FOREIGN_DRIVER_LICENSE',
  'DOC_US_DRIVER_LICENSE',
  'DOC_PASSPORT',
  'DOC_US_STATE_ID',
  'DOC_MARRIAGE_CERTIFICATE',
  'DOC_VEHICLE',
  'DOC_INSURANCE_POLICY_AUTO',
  'DOC_INSURANCE_POLICY_AUTO_CHANGE_QUOTE',
  'DOC_INSURANCE_PREMIUM_FINANCE_LOAN_CONTRACT',
  'DOC_OTHER',
  'OTHER',
]

export enum QuotesStatus {
  Success = 'SUCCESS',
}

export enum InclusionStatus {
  add = 'ADD',
  exclude = 'EXCLUDE',
}

export enum EndorsementMetaData {
  Drivers = 'drivers',
  Vehicles = 'vehicles',
}

export enum InclusionStatusLocalized {
  ADD = 'included',
  EXCLUDE = 'excluded',
}

export const CustomerTaskTypes = ['DOCUMENT_SIGNATURE', 'DOCUMENT_UPLOAD']

export enum RateCall {
  rc1 = 'RATE_CALL1',
  rc15 = 'RATE_CALL1_5',
  final = 'RATE_CALL_FINAL',
}

export enum SessionLogsTabsValues {
  Aws = 'aws',
  Session = 'session',
}

export enum CoverageType {
  Basic = 'BASIC',
  BasicPlus = 'BASIC_PLUS',
}

export const TagTypeSeverity = {
  danger: 5,
  neutral: 1,
  primary: 3,
  secondary: 2,
  warning: 4,
}

export enum ContextType {
  Application = 'APPLICATION',
  Task = 'TASK',
  Product = 'PRODUCT',
  ProductChange = 'PRODUCT_CHANGE',
  Document = 'DOCUMENT',
  Payment = 'PAYMENT',
}

export const CommunicationLogName = {
  MANUAL: {
    APPLICATION: 'MANUAL::APPLICATION',
    OTHER: 'MANUAL::OTHER',
    PAYMENT: 'MANUAL::PAYMENT',
    POLICY: 'MANUAL::POLICY',
  },
}

export const InclusionStatusTags = {
  [InclusionStatus.add]: TagType.Secondary,
  [InclusionStatus.exclude]: TagType.Danger,
}

export enum StatusIconType {
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
}

export enum CommunicationChannelType {
  Sms = 'SMS',
  Voice = 'VOICE',
  Email = 'EMAIL',
  Alert = 'ALERT',
  Mail = 'MAIL',
}

export const ProductStatusTag = {
  ACTIVE: TagType.Primary,
  CANCEL: TagType.Danger,
  INACTIVE: TagType.Danger,
  PENDING: TagType.Warning,
}

export enum ApplicationStatus {
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  DRIVER_INCIDENT_DISCOVERY = 'DRIVER_INCIDENT_DISCOVERY',
  DRIVER_VEHICLE_DISCOVERY = 'DRIVER_VEHICLE_DISCOVERY',
  EXPIRED = 'EXPIRED',
  ISSUER_QUESTIONS = 'ISSUER_QUESTIONS',
  LEAD = 'LEAD',
  LOAN_PREMIUM_FINANCE_SIGNATURE_PENDING = 'LOAN_PREMIUM_FINANCE_SIGNATURE_PENDING',
  LOAN_PREMIUM_FINANCE_SIGNED = 'LOAN_PREMIUM_FINANCE_SIGNED',
  LOAN_PREQUALIFIED_APPROVED = 'LOAN_PREQUALIFIED_APPROVED',
  LOAN_PREQUALIFIED_PENDING = 'LOAN_PREQUALIFIED_PENDING',
  NEW = 'NEW',
  POLICY_BOUND = 'POLICY_BOUND',
  POLICY_BOUND_PENDING = 'POLICY_BOUND_PENDING',
  POLICY_GENERATED = 'POLICY_GENERATED',
  POLICY_GENERATION = 'POLICY_GENERATION',
  POLICY_GENERATION_AGENT_LOCKED = 'POLICY_GENERATION_AGENT_LOCKED',
  POLICY_GENERATION_PENDING = 'POLICY_GENERATION_PENDING',
  POLICY_SIGNATURE_PENDING = 'POLICY_SIGNATURE_PENDING',
  POLICY_SIGNED = 'POLICY_SIGNED',
  PROOF_DOCUMENTATION = 'PROOF_DOCUMENTATION',
  PROOF_DOCUMENTATION_PENDING = 'PROOF_DOCUMENTATION_PENDING',
  QUOTES_FINAL = 'QUOTES_FINAL',
  QUOTES_FINAL_ACCEPTED = 'QUOTES_FINAL_ACCEPTED',
  QUOTES_FINAL_AGENT_LOCKED = 'QUOTES_FINAL_AGENT_LOCKED',
  QUOTES_FINAL_BOUNCED = 'QUOTES_FINAL_BOUNCED',
  QUOTES_FINAL_MORE_INFO_NEEDED = 'QUOTES_FINAL_MORE_INFO_NEEDED',
  QUOTES_FINAL_PENDING = 'QUOTES_FINAL_PENDING',
  QUOTES_RATE_CALL1 = 'QUOTES_RATE_CALL1',
  QUOTES_RATE_CALL1_5 = 'QUOTES_RATE_CALL1_5',
  QUOTES_RATE_CALL1_5_PENDING = 'QUOTES_RATE_CALL1_5_PENDING',
  QUOTES_RATE_CALL1_ANON = 'QUOTES_RATE_CALL1_ANON',
  QUOTES_UNPROCESSABLE = 'QUOTES_UNPROCESSABLE',
}

export enum DocumentType {
  IdDriverLicense = 'ID_DRIVER_LICENSE',
  IdMatricularId = 'ID_MATRICULAR_ID',
  IdOther = 'ID_OTHER',
  IdPassport = 'ID_PASSPORT',
  IdStateId = 'ID_STATE_ID',
  InsurancePolicyAuto = 'INSURANCE_POLICY_AUTO',
  InsurancePolicyAutoEmail = 'INSURANCE_POLICY_AUTO_EMAIL',
  InsurancePolicyAutoEmailRaw = 'INSURANCE_POLICY_AUTO_EMAIL_RAW',
  InsurancePolicyAutoEvidence = 'INSURANCE_POLICY_AUTO_EVIDENCE',
  InsurancePremiumFinanceLoanContract = 'INSURANCE_PREMIUM_FINANCE_LOAN_CONTRACT',
  Other = 'OTHER',
  PaymentOrderReceipt = 'PAYMENT_ORDER_RECEIPT',
  ProfilePhoto = 'PROFILE_PHOTO',
  Signature = 'SIGNATURE',
  SignatureInitial = 'SIGNATURE_INITIAL',
  // Application documents
  ProofAddress = 'PROOF_ADDRESS',
  ProofPriorInsurance = 'PROOF_PRIOR_INSURANCE',
  VehicleCorners = 'VEHICLE_CORNERS',
  VehicleVin = 'VEHICLE_VIN',
  VehicleOdometer = 'VEHICLE_ODOMETER',
}

export enum StorageKey {
  Language = 'i18nextLng',
  ExpandedNav = 'isExpanded',
  SelectedLocation = 'selected-location',
  ColorScheme = 'color-scheme',
  _CypressToken = '@cypress/idToken',
  LastAppVersion = '@ocho/last-app-version',
}

export enum Directions {
  LTR = 'ltr',
  RTL = 'rtl',
}

export enum Side {
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom',
}

export enum TransactionStatus {
  Error = 'error',
  Failed = 'failed',
  Pending = 'pending',
  Queued = 'queued',
  Reversed = 'reversed',
  Success = 'success',
}

export enum CustomerTaskStatus {
  pending = 'PENDING',
  canceled = 'CANCELED',
  complete = 'COMPLETE',
  submitted = 'SUBMITTED',
  agentLocked = 'AGENT_LOCKED',
}

export enum TaskStatus {
  cancel = 'CANCEL',
  snooze = 'SNOOZE',
  pending = 'PENDING',
  complete = 'COMPLETE',
  agentLock = 'AGENT_LOCK',
  needReview = 'NEED_REVIEW',
}

export enum TaskTypes {
  Cancel = 'CANCEL',
  CancelCarrier = 'CANCEL_CARRIER',
  CancelCarrierConfirmation = 'CANCEL_CARRIER_CONFIRMATION',
  Email = 'EMAIL_CARRIER',
  NotificationCarrier = 'NOTIFICATION_CARRIER',
  PaymentAdvanceFailure = 'PAYMENT_ADVANCE_FAILURE',
  PaymentProductCancel = 'PAYMENT_PRODUCT_CANCEL', // Payment after product is cancelled
  RenewalOffer = 'RENEWAL_OFFER',
}

export enum DocumentStatus {
  UNSIGNED = 'UNSIGNED',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export enum FiltersType {
  multiple = 'multiple',
  single = 'single',
}

export enum HttpStatus {
  Ok = 200,
  BadRequest = 400,
  NotFound = 404,
  TooManyRequests = 429,
  ServerError = 500,
}

export enum CarOwnership {
  Owned = 'OWNED',
  Leased = 'LEASED',
  Financed = 'FINANCED',
}

export enum DrivingRecord {
  Perfect = 'PERFECT',
  Great = 'GREAT',
  Good = 'GOOD',
  NotGood = 'NOT_GOOD',
}

export enum EndorsementTypes {
  Reinstate = 'REINSTATE',
  AddDriver = 'ADD_DRIVER',
  AddVehicle = 'ADD_VEHICLE',
  DeleteDriver = 'DELETE_DRIVER',
  DeleteVehicle = 'DELETE_VEHICLE',
  ChangeAddress = 'CHANGE_ADDRESS',
  AddLienHolder = 'ADD_LIEN_HOLDER',
  ChangeCoverage = 'CHANGE_COVERAGE',
  NoProofOfPrior = 'NO_PROOF_OF_PRIOR',
  ChangeLienHolder = 'CHANGE_LIEN_HOLDER',
  NoProofOfAddress = 'NO_PROOF_OF_ADDRESS',
  ChangeMaritalStatus = 'CHANGE_MARITAL_STATUS',
  ViolationsDiscovered = 'VIOLATIONS_DISCOVERED',
  AccidentsClaimsDiscovered = 'ACCIDENTS_CLAIMS_DISCOVERED',
  DriverLicenseVerification = 'DRIVER_LICENSE_VERIFICATION',
  AdditionalDriversDiscovered = 'ADDITIONAL_DRIVERS_DISCOVERED',
}

/*
 {@link https://en.wikipedia.org/wiki/List_of_tz_database_time_zones}
 */
export enum TimeZones {
  UsPacific = 'US/Pacific',
  UsCentral = 'Us/Central',
}

export enum Order {
  descending = 'descending',
  ascending = 'ascending',
}

export enum DriverLicenseStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Revoked = 'REVOKED',
  Suspended = 'SUSPENDED',
  Temporary = 'TEMPORARY',
}

export enum EntityStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  CLOSED = 'CLOSED',
}

export enum CustomerProductTabs {
  General = 'general',
  AmortizationSchedules = 'amortizationSchedules',
  Events = 'events',
  IvanTransactions = 'ivanTransactions',
  EarnedAmount = 'earnedAmount',
  Documents = 'documents',
}

export enum CustomerPaymentsTabs {
  Transactions = 'transactions',
  Schedules = 'schedules',
  Accounts = 'accounts',
}

export enum PhoneTwilioType {
  Mobile = 'mobile',
}

export enum TypeValuesErrors {
  Number = 'The value should be a number',
}

export enum TypeValues {
  Currency = 'currency',
  Tag = 'tag',
}

export enum ProductStatus {
  Active = 'ACTIVE',
  Cancel = 'CANCEL',
  Expired = 'EXPIRED',
}

export enum ProductAccountStatus {
  Active = 'ACTIVE',
  ActiveInFuture = 'ACTIVE_IN_FUTURE',
  ActiveRenewalEligible = 'ACTIVE_RENEWAL_ELIGIBLE',
  CancellationPending = 'CANCELLATION_PENDING',
  Cancelled = 'CANCELLED',
  CancelledReinstatementEligible = 'CANCELLED_REINSTATEMENT_ELIGIBLE',
  CancelledWithBalance = 'CANCELLED_WITH_BALANCE',
  Expired = 'EXPIRED',
  ReinstatementPending = 'REINSTATEMENT_PENDING',
}

export enum ChangeRequestType {
  Cancel = 'CANCEL',
  Reactivate = 'REACTIVATE',
  ChangeCoverage = 'CHANGE_COVERAGE',
}

export enum PremiumFinanceContractType {
  Full = 'FULL',
  NonFinanced = 'NON_FINANCED',
  Standard = 'STANDARD',
}

export enum LoanPaymentFrequency {
  MONTHLY = 'monthly',
  BI_WEEKLY = 'biWeekly',
  SEMI_MONTHLY = 'semiMonthly',
  WEEKLY = 'weekly',
  OTHER = 'other',
}

export enum FlagStatus {
  Warning = 'Warning',
  Critical = 'Critical',
  Stable = 'Stable',
}

export enum ProductType {
  InsuranceAuto = 'INSURANCE_AUTO',
  InsuranceRental = 'INSURANCE_RENTAL',
  InsuranceTermLife = 'INSURANCE_TERM_LIFE',
  LoanPremiumFinance = 'LOAN_PREMIUM_FINANCE',
}

export enum PaymentType {
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  Cash = 'CASH',
  Checking = 'CHECKING',
  CreditCard = 'CREDIT_CARD',
  DebitCard = 'DEBIT_CARD',
  PayNearMeOrder = 'PAY_NEAR_ME_ORDER',
  Saving = 'SAVING',
}

export enum IssuerName {
  Acceptance = 'ACCEPTANCE',
  AmericanAccess = 'AMERICAN_ACCESS',
  AspireGeneral = 'ASPIRE_GENERAL',
  BridgerSegundo = 'BRIDGER_SEGUNDO',
  BristolWest = 'BRISTOL_WEST',
  CommonWealthCasuality = 'COMMONWEALTH_CASUALTY',
  DairyLand = 'DAIRYLAND',
  DairyLandB = 'DAIRYLAND_B',
  FalconGroup = 'FALCON_GROUP',
  FoundersFirstChoice = 'FOUNDERS_FIRST_CHOICE',
  FoundersMetro = 'FOUNDERS_METRO',
  Gainsco = 'GAINSCO',
  LamarGeneralAgency = 'LAMAR_GENERAL_AGENCY',
  NationalGeneral = 'NATIONAL_GENERAL',
  Progressive = 'PROGRESSIVE',
  UniqueInsuranceCompany = 'UNIQUE_INSURANCE_COMPANY',
}

export enum AttachmentContent {
  Image = 'image/*',
  TexPlain = 'text/plain',
  Html = 'text/html; charset=utf-8',
  ApplicationPdf = 'application/pdf',
}

export enum AttachmentType {
  DriverLicense = 'ID_DRIVER_LICENSE',
}

export enum PaymentsScheduleStatus {
  Error = 'ERROR',
  Failed = 'FAILED',
  Aborted = 'ABORTED',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Cancelled = 'CANCELLED',
}

export enum AdmonitionType {
  Info = 'info',
  Warning = 'warning',
  Critical = 'critical',
}

export enum EndpointType {
  Sms = 'SMS',
  Email = 'EMAIL',
}

export enum IssuerQuestionTags {
  Sr22 = 'sr22',
}

export enum PaymentAccountsMethod {
  AchDebit = 'ACH_DEBIT',
  Adjustment = 'ADJUSTMENT',
  Cash = 'CASH',
  Debit = 'DEBIT',
  PayNearMe = 'PAY_NEAR_ME',
}

export enum PaymentTypeMethod {
  Manual = 'MANUAL',
  ManualAuto = 'MANUAL_AUTO',
}

export enum ConsentStatus {
  accepted = 'ACCEPTED',
  declined = 'DECLINED',
}

export enum AccountField {
  City = 'city',
  HolderName = 'holderName',
  PostalCode = 'postalCode',
  State = 'state',
  Street1 = 'street1',
  Street2 = 'street2',
}

export enum Actions {
  Delete = 'delete',
}

export enum PermissionSegments {
  Payment = 'payment',
}

export enum ProductActions {
  Endorse = 'endorse',
  Cancel = 'cancel',
  Reactivate = 'reactivate',
}

export enum MaritalStatus {
  DomesticPartner = 'DOMESTIC_PARTNER',
  Married = 'MARRIED',
  Single = 'SINGLE',
  Separated = 'SEPARATED',
  Widowed = 'WIDOWED',
  Divorced = 'DIVORCED',
  Unknown = 'UNKNOWN',
}

export enum DispositionDirection {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
}

export enum NoteType {
  Comment = 'COMMENT',
  HelpdeskTicket = 'HELPDESK_TICKET',
  Inbound = DispositionDirection.Inbound,
  Outbound = DispositionDirection.Outbound,
}

export enum DispositionKey {
  Actions = 'actions',
  Directions = 'directions',
  AdditionalCommunications = 'additionalCommunications',
  Channels = 'channels',
  ContactReasons = 'contactReasons',
  Dispositions = 'dispositions',
  SubActions = 'subActions',
  SubAdditionalCommunications = 'subAdditionalCommunications',
}

export enum ToastType {
  Error = 'error',
  Info = 'info',
  Success = 'success',
}

export enum DriversVehiclesOperationsKeys {
  InclusionStatus = 'inclusionStatus',
}

export enum DriverLicenseTypeKey {
  DriverLicense = 'DRIVER_LICENSE',
  MatriculaConsular = 'MATRICULA_CONSULAR',
  Other = 'OTHER_DRIVING_PERMIT',
}

export enum Purpose {
  DownPayment = 'DOWN_PAYMENT',
  Installment = 'INSTALLMENT',
  Endorsement = 'ENDORSEMENT',
  Reinstatement = 'REINSTATEMENT',
  Other = 'OTHER',
}

export enum ReconciliationStatus {
  Approve = 'APPROVE',
  Dispute = 'DISPUTE',
  NeedAttention = 'NEED_ATTENTION',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
}

export enum TransactionPurpose {
  Cancel = 'CANCEL',
  CancelNonPayment = 'CANCEL_NON_PAYMENT',
  DownPayment = 'DOWN_PAYMENT',
  Endorsement = 'ENDORSEMENT',
  Installment = 'INSTALLMENT',
  New = 'NEW',
  Other = 'OTHER',
  RefundCancellation = 'REFUND_CANCELLATION',
  RefundOverPayment = 'REFUND_OVERPAYMENT',
  Reinstatement = 'REINSTATEMENT',
  Renewal = 'RENEWAL',
  Unknown = 'UNKNOWN',
}

export enum LoanEventType {
  Cancel = 'CANCEL',
  ChargeOff = 'CHARGE_OFF',
  Create = 'CREATE',
  FeeCredit = 'FEE_CREDIT',
  FeePaymentBounced = 'FEE_PAYMENT_BOUNCED',
  PaidOff = 'PAID_OFF',
  PaycheckDateAdjustment = 'PAYCHECK_DATE_ADJUSTMENT',
  Payment = 'PAYMENT',
  PaymentAdjustment = 'PAYMENT_ADJUSTMENT',
  PaymentReversal = 'PAYMENT_REVERSAL',
  Reactivate = 'REACTIVATE',
  Rewrite = 'REWRITE',
}

export enum InsuranceStatus {
  Insured = 'Insured',
  Uninsured = 'Uninsured',
}

export enum MarketingSurveyReasonForShopping {
  SWITCH = 'SWITCH',
  NEW_CAR = 'NEW_CAR',
  GET_INSURED = 'GET_INSURED',
  OTHER = 'OTHER',
}

export enum OperativeSystems {
  Android = 'android',
  Ios = 'ios',
  Linux = 'linux',
  Macos = 'mac os',
  Windows = 'windows',
}

export enum Browsers {
  Chrome = 'chrome',
  Edge = 'edge',
  Firefox = 'firefox',
  Safari = 'safari',
}
