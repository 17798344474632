// Import Sentry first
// eslint-disable-next-line simple-import-sort/imports
import '@/utils/sentry'

import { captureException } from '@sentry/react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import { verifyEnv } from '@/utils/env'

import browserRouter from '@/Router'

import '@/utils/localization'
import '@/utils/aws/amplify'

import './reset.css'

const container = document.getElementById('root')
const reactRoot = createRoot(container)

try {
  verifyEnv()

  reactRoot.render(<RouterProvider router={browserRouter} />)
} catch (error: any) {
  captureException(error)

  // Display actionable message
  document.body.innerHTML += '<p>Please update your browser.</p>'
}
