import type { GetLoginEventsData } from '@/utils/api/api'

export enum AuthEventTypes {
  SignIn = 'SignIn',
  SignUp = 'SignUp',
  SignOut = 'SignOut',
  ForgotPassword = 'ForgotPassword',
  ChangePassword = 'ChangePassword',
}

export enum AuthEventResponses {
  Pass = 'Pass',
  InProgress = 'InProgress',
  Fail = 'Fail',
}

export type AuthEvent = {
  eventId: string
  eventTypeAsString: AuthEventTypes
  creationDate: Date
  eventResponse: 'Pass' | 'InProgress' | 'Fail'
  eventRisk: {
    riskDecision: 'ACCOUNT_TAKEOVER' | 'NO_RISK'
    compromisedCredentialsDetected: boolean
  }
  challengeResponses: {
    challengeName: 'UNKNOWN_TO_SDK_VERSION' | 'PASSWORD'
    challengeResponse: 'Success' | 'Failure' | 'CodeMismatch' | 'ExpiredCode'
  }[]
  eventContextData: {
    ipAddress: string
    deviceName: string
    city: string
    country: string
  }
}

export type LoginEventsResponse = GetLoginEventsData & {
  data: {
    authEvents: AuthEvent[]
  }
}
