import { getIdemPotencyKeyHeader } from '@/utils'
import { axiosInstance } from '@/utils/api'
import { Missing } from '@/utils/errors'

import type {
  CreateLeadRequest,
  CreateLeadResponse,
  CreatePartnerLocationResponse,
  CreatePartnerResponse,
  GetEmployeeInfoResponse,
  GetLocationsResponse,
  GetPartnerLocationsResponse,
  GetPartnerMetadataResponse,
  GetPartnersResponse,
  GetSignatureAnchorsResponse,
  PartnerApplicationData,
  ReportParams,
  UpdatePartnerLocationResponse,
  UpdatePartnerResponse,
} from './types'

export function getLocations(params: { name: string }) {
  if (!params.name) {
    throw new Error(Missing.partnerName)
  }

  return axiosInstance
    .get<GetLocationsResponse>(`/integrations/partners/locations`, { params })
    .then((response) => response.data?.data)
}

export function getMetadata(params: { name: string }) {
  if (!params.name) {
    throw new Error(Missing.partnerId)
  }

  return axiosInstance
    .get<GetPartnerMetadataResponse>('/integrations/partners/metadata', {
      params,
    })
    .then((response) => response.data)
}

export function getPartnerLocations(partnerId: string) {
  return axiosInstance
    .get<GetPartnerLocationsResponse>(`/partners/${partnerId}/locations`)
    .then((response) => response?.data?.data)
}

export function createLocation(
  partnerId: string,
  body: Record<string, string>,
) {
  if (!partnerId) {
    throw new Error(Missing.partnerId)
  }
  return axiosInstance.post<CreatePartnerLocationResponse>(
    `/partners/${partnerId}/locations`,
    body,
    { headers: getIdemPotencyKeyHeader() },
  )
}

export function updateLocation(
  partnerId: string,
  locationId: string,
  body: unknown,
) {
  if (!partnerId) {
    throw new Error(Missing.partnerId)
  }

  if (!locationId) {
    throw new Error(Missing.locationId)
  }
  return axiosInstance
    .put<UpdatePartnerLocationResponse>(
      `/partners/${partnerId}/locations/${locationId}`,
      body,
      { headers: getIdemPotencyKeyHeader() },
    )
    .then((response) => response.data)
}

export function create(body: unknown) {
  return axiosInstance.post<CreatePartnerResponse>('/partners', body, {
    headers: getIdemPotencyKeyHeader(),
  })
}

export function getPartners() {
  return axiosInstance
    .get<GetPartnersResponse>('/partners')
    .then((response) => response?.data?.data)
}

export function update(partnerId: string, body: unknown) {
  return axiosInstance
    .put<UpdatePartnerResponse>(`/partners/${partnerId}`, body, {
      headers: getIdemPotencyKeyHeader(),
    })
    .then((response) => response.data)
}

export function createAppLink(customerId: string) {
  return axiosInstance.post(
    `/integrations/partners/customers/${customerId}/appLinks`,
    {},
    { headers: getIdemPotencyKeyHeader() },
  )
}

export function createLead(body: CreateLeadRequest) {
  return axiosInstance
    .post<CreateLeadResponse>('/integrations/partners/leads', body, {
      headers: getIdemPotencyKeyHeader(),
    })
    .then((response) => response.data)
}

export function getReceipt(customerId: string, receiptId: string) {
  return axiosInstance
    .get(`/integrations/partners/customers/${customerId}/document/${receiptId}`)
    .then((response) => response.data)
}

export function getReports(params: ReportParams) {
  return axiosInstance
    .get(`/integrations/partners/reports/embeddedUrl/partnerAgent`, {
      params,
    })
    .then((response) => response?.data?.data)
}

export function getPartnerData(partnerId: string) {
  return axiosInstance
    .get(`/partners/${partnerId}`)
    .then((response) => response?.data?.data)
}

export type UpdateEmployeeData = {
  city: string
  consentTerms: string
  country: string
  dateOfBirth: string
  email: string
  familyName: string
  givenName: string
  middleName: string
  postalCode: string
  preferredLanguage: string
  taxId: string
  state: string
  street1: string
  street2: string
}

export function updateEmployee(employeeId: string, data: UpdateEmployeeData) {
  return axiosInstance
    .put(`/integrations/partners/employees/${employeeId}`, data, {
      headers: getIdemPotencyKeyHeader(),
    })
    .then((response) => response?.data)
}

export function getEmployeeSelf() {
  return axiosInstance
    .get<GetEmployeeInfoResponse>('/integrations/partners/employees/self')
    .then((response) => response?.data?.data)
}
export function generateEmployeeContract(employeeId: string) {
  if (!employeeId) throw new Error(Missing.employeeId)

  return axiosInstance
    .put(
      `/integrations/partners/employees/${employeeId}/documents/contract`,
      {},
      { headers: getIdemPotencyKeyHeader() },
    )
    .then((response) => response?.data?.data)
}

export function getSignatureAnchors(employeeId: string) {
  if (!employeeId) throw new Error(Missing.employeeId)

  return axiosInstance
    .get<GetSignatureAnchorsResponse>(
      `/integrations/partners/employees/${employeeId}/documents/contract/signatureAnchors`,
    )
    .then((response) => response?.data?.data)
}

export function signEmployeeContract(
  employeeId: string,
  body: {
    type: 'PUT'
    path: 'contract.signature'
    value: string
  }[],
) {
  return axiosInstance
    .patch(
      `/integrations/partners/employees/${employeeId}/documents/contract/sign`,
      body,
      { headers: getIdemPotencyKeyHeader() },
    )
    .then((response) => response?.data?.data)
}

export function getPartnerApplications(
  applicationId: string,
): Promise<PartnerApplicationData> {
  return axiosInstance
    .get(`/integrations/partners/applications/${applicationId}`)
    .then((response) => response?.data?.data)
}
export function getPartnerCompensation(applicationId: string) {
  return axiosInstance
    .get(`integrations/partners/compensation/applications/${applicationId}`)
    .then((response) => response?.data?.data)
}

export function updatePartnerApplication(body: any, applicationId: string) {
  return axiosInstance
    .patch(`/integrations/partners/applications/${applicationId}`, body, {
      headers: getIdemPotencyKeyHeader(),
    })
    .then((response) => response?.data)
}
