import { create } from 'zustand'
import { combine, persist } from 'zustand/middleware'

import { TimeZones } from '@/utils/constants/enums'

export type AgentLocation = Record<string, string>

/**
 * Store
 */

export const usePreferencesStore = create(
  persist(
    combine(
      {
        currentLocation: null as null | AgentLocation,
        currentTimeZone: TimeZones.UsPacific,
      },
      (set) => ({
        setCurrentTimezone: (timeZone: TimeZones) =>
          set((state) => ({ ...state, currentTimeZone: timeZone })),
        setLocation: (location: AgentLocation) =>
          set((state) => ({ ...state, currentLocation: location })),
      }),
    ),
    {
      name: 'ocho-preferences-v2',
    },
  ),
)

/**
 * Helpers
 */

export function useCurrentTimeZone() {
  return usePreferencesStore((state) => state.currentTimeZone)
}

export function useCurrentLocation() {
  return usePreferencesStore((state) => state.currentLocation)
}
