import { Arrow as RadixTooltipArrow, Content } from '@radix-ui/react-tooltip'
import styled, { keyframes } from 'styled-components'

const slideUpAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(2px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const slideRightAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-2px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

const slideDownAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-2px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const slideLeftAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateX(2px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

export const StyledContent = styled(Content)`
  max-width: var(--size--tooltip-content);
  border-radius: var(--corner_radius--small);
  padding: 10px 15px;
  background-color: var(--color--background__neutral);
  font-size: var(--font_size--small);
  line-height: 1;
  user-select: none;
  margin-bottom: var(--space--xsmall);
  box-shadow:
    rgb(14 19 22 / 0.35) 0 10px 38px -10px,
    rgb(14 19 22 / 0.2) 0 10px 20px -15px;
  z-index: var(--z_index--overlay);

  @media (prefers-reduced-motion: no-preference) {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;

    &[data-state='delayed-open'] {
      &[data-side='top'] {
        animation-name: ${slideDownAndFade};
      }

      &[data-side='right'] {
        animation-name: ${slideLeftAndFade};
      }

      &[data-side='bottom'] {
        animation-name: ${slideUpAndFade};
      }

      &[data-side='left'] {
        animation-name: ${slideRightAndFade};
      }
    }
  }
`

export const Arrow = styled(RadixTooltipArrow)`
  fill: var(--color--background__neutral);
`
