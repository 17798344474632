import { getIdemPotencyKeyHeader } from '@/utils'
import { axiosInstance } from '@/utils/api'
import type * as payment from '@/utils/api/payment'
import { ProductAccountStatus } from '@/utils/constants/enums'
import { Missing } from '@/utils/errors'

import type {
  GetLoansResponse,
  GetProductInstancesResponse,
  Product,
  ProductDocumentsResponse,
} from './types'

const CANCELLED_ACCOUNT_STATUSES = [
  ProductAccountStatus.Cancelled,
  ProductAccountStatus.CancelledWithBalance,
  ProductAccountStatus.CancelledReinstatementEligible,
]

export function getLatestProduct(
  data?: Awaited<ReturnType<typeof payment.getProducts>>,
): (Product & { hasCancelledStatus: boolean }) | null {
  let latestProduct
  // If there is a latest product id, find the product with that id
  if (data?.latestProductId) {
    latestProduct = data?.products?.find(
      (product) => product.productId === data?.latestProductId,
    )
    // If there is more than one product, find the one with the latest expiration date
  } else if (data?.products?.length && data?.products?.length > 1) {
    latestProduct = data.products.reduce((acc, product) => {
      if (!acc) return product
      if (acc.expirationDate < product.expirationDate) return product
      return acc
    })
  } else if (data?.products?.length === 1) {
    // eslint-disable-next-line prefer-destructuring
    latestProduct = data.products[0]
  }

  if (!latestProduct) return null

  return {
    ...latestProduct,
    /** Derived from account status string value */
    hasCancelledStatus: CANCELLED_ACCOUNT_STATUSES.includes(
      latestProduct.accountStatus,
    ),
  }
}

export function getDocuments(productId: string) {
  if (!productId) {
    throw new Error(Missing.productId)
  }

  return axiosInstance
    .get<ProductDocumentsResponse>(`/products/${productId}/documents`)
    .then((response) => response?.data?.data)
}

export function getLoans(productId: string) {
  if (!productId) {
    throw new Error(Missing.productId)
  }

  return axiosInstance
    .get<GetLoansResponse>(`/products/${productId}/loans`)
    .then((response) => response?.data?.data)
}

export function getInstances() {
  return axiosInstance
    .get<GetProductInstancesResponse>('/products/instances')
    .then((response) => response?.data?.data)
}

export function renewalApplication({
  productId,
  forceCreate,
}: {
  productId: string
  forceCreate?: boolean
}) {
  if (!productId) {
    throw new Error(Missing.productId)
  }

  return axiosInstance
    .post(`/autoFlow/products/${productId}/renewals`, null, {
      headers: getIdemPotencyKeyHeader(),
      params: {
        forceNewApp: forceCreate,
      },
    })
    .then((response) => response?.data)
}

export function searchByPolicy(policyNumber: string) {
  if (!policyNumber) {
    throw new Error(Missing.policyNumber)
  }

  return axiosInstance
    .get(`/products/search`, {
      params: {
        limit: 10,
        offset: 0,
        query: policyNumber,
      },
    })
    .then(
      (response) =>
        response?.data?.data?.results ?? (response?.data as any).results,
    )
}

export function getEarnedAmount(productId: string) {
  if (!productId) {
    throw new Error(Missing.productId)
  }

  return axiosInstance
    .get(`/products/${productId}/earnedAmounts/summary`, {
      params: {
        withDailyAmount: true,
      },
    })
    .then((response) => response?.data?.data)
}

export function getEarnedAmountDiagram(productId: string) {
  if (!productId) {
    throw new Error(Missing.productId)
  }

  return axiosInstance
    .get(`/products/${productId}/earnedAmounts/summary/diagrams`)
    .then((response) => response?.data?.data)
}
