const {
  VITE_IS_PARTNER,
  VITE_SERVICING_POOL_ID,
  VITE_PARTNER_POOL_ID,
  VITE_SERVICING_POOL_WEB_CLIENT_ID,
  VITE_PARTNER_POOL_WEB_CLIENT_ID,
} = import.meta.env
import type { ResourcesConfig } from 'aws-amplify'

const USER_POOL_ID = VITE_IS_PARTNER
  ? VITE_PARTNER_POOL_ID
  : VITE_SERVICING_POOL_ID
const USER_POOL_WEB_CLIENT_ID = VITE_IS_PARTNER
  ? VITE_PARTNER_POOL_WEB_CLIENT_ID
  : VITE_SERVICING_POOL_WEB_CLIENT_ID

const awsConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolClientId: USER_POOL_WEB_CLIENT_ID,
      userPoolId: USER_POOL_ID,
    },
  },
}

export { USER_POOL_ID }
export default awsConfig
