import { useCallback } from 'react'
import { Text } from '@ocho/aurora'
import Cleave from 'cleave.js/react'
import type { ComponentProps, InputHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import Copy from '@/components/Copy'
import { InfoTooltip } from '@/components/InfoTooltip'
import { Flex } from '@/components/system'

import { switchHandler } from '@/utils'
import { US_COUNTRY_CODE } from '@/utils/constants'
import type { Directions } from '@/utils/constants/enums'

import { Error } from '@/GlobalStyles'

import 'cleave.js/dist/addons/cleave-phone.us'

import * as Styled from './styled'

type Props = {
  label?: string
  error?: string
  prefix?: string
  postfix?: string
  pattern?: string
  readOnly?: boolean
  multiline?: boolean
  infoLabel?: string
  decorator?: Decorator
  reducedLabel?: boolean
  mask?: ComponentProps<typeof Cleave>['options']
  containerStyle?: ComponentProps<typeof Styled.InputContainer>['style']
  allowToCopy?: boolean
  allowToPaste?: boolean
  value?: string | number
  copySuccessLabel?: string
} & InputHTMLAttributes<HTMLInputElement>

function initialMask(type: string) {
  return switchHandler(
    type,
    {
      date: () => ({ date: true, datePattern: ['m', 'd', 'Y'] }),
      tel: () => ({ phone: true, phoneRegionCode: US_COUNTRY_CODE }),
    },
    undefined,
  )
}

function TextInput(props: Props) {
  const { t } = useTranslation()

  const {
    label,
    name,
    prefix,
    error,
    infoLabel,
    multiline,
    id = name,
    disabled,
    readOnly,
    decorator,
    postfix,
    containerStyle,
    value = '',
    type = 'text',
    mask = initialMask(type),
    required = false,
    allowToCopy = false,
    allowToPaste = true,
    ...inputProps
  } = props

  const onPaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      if (!allowToPaste) {
        event.preventDefault()
        return false
      }
    },
    [allowToPaste],
  )

  if (value === null && readOnly) return null

  const input = (
    <Styled.Input
      $decorator={decorator}
      $prefix={prefix}
      as={mask ? Cleave : multiline ? 'textarea' : 'input'}
      name={name}
      {...(mask ? { options: mask } : {})}
      aria-invalid={Boolean(error)}
      aria-label={name}
      disabled={disabled}
      id={id}
      onPaste={onPaste}
      readOnly={readOnly}
      required={required}
      type={type === 'date' ? 'text' : type}
      value={value}
      {...inputProps}
    />
  )

  function getErrorMessage(error?: string) {
    // If it has a space it's already a translated message
    // this is a temporary fix until we have a better way to handle this
    if (typeof error === 'string' && error.includes(' ')) return error
    if (error) return t(error)
    return null
  }

  return (
    <Styled.InputContainer style={containerStyle}>
      {decorator ? (
        <Styled.DecoratorContainer $direction={decorator.direction}>
          {decorator.icon}
        </Styled.DecoratorContainer>
      ) : null}
      {label ? (
        <Styled.Label htmlFor={id}>
          <Flex alignItems="center" gap="var(--space--xsmall)">
            <Text $size="small" $weight="medium">
              {label}
              {required || readOnly ? null : (
                <Text $size="small" $weight="bold">
                  {t('components.textInput.optional.label')}
                </Text>
              )}
            </Text>
            {infoLabel ? (
              <InfoTooltip side="top">
                <p>{infoLabel}</p>
              </InfoTooltip>
            ) : null}
          </Flex>
          {allowToCopy ? (
            <Copy value={value}>
              <Styled.InputWrapper $readOnly>{input}</Styled.InputWrapper>
            </Copy>
          ) : (
            <Styled.InputWrapper $prefix={prefix} $readOnly={readOnly}>
              {input}
              {postfix ? (
                <Styled.Postfix $valueLength={value?.toString()?.length}>
                  {postfix}
                </Styled.Postfix>
              ) : null}
            </Styled.InputWrapper>
          )}
        </Styled.Label>
      ) : (
        input
      )}
      <Error aria-hidden={!error}>{getErrorMessage(error)}</Error>
    </Styled.InputContainer>
  )
}

export type Decorator = {
  icon: JSX.Element
  direction?: Directions
}

export default TextInput
